.queue,.breached,.amount-tickets{
    min-height: 93vh;
    text-align: center;
}


.grid-container {
    min-height: 93vh;
    background-color: #1b2124;
    display: grid;
    grid-template-columns: repeat(2, minmax(0,1fr));
    gap: 0px;
    /* align-items: center; */
    /* justify-content: center; */
    font-size: calc(10px + 2vmin);
    color: white;
    padding:0%;
    min-width: 100vh;
}

.queue,.amount-tickets,.breached{
    display: grid;
    grid-auto-rows: 1fr;
    grid-row-gap: 0;
}

.queueType{
    display:flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.queueDescription{
    font-size: 70%;
    color: #F3F5F6;
}

p{
    font-size: calc(17px + 2vmin);
    margin: 0px 20px;
}
.App-header {
  background-color: #1d5a60;
  height: 7vh;
  width:100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
  margin: 0;
}

.titleDiv,.imageDiv{
  width: 100%;
  height:100%;
  margin: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.title{
  position: absolute;
  left:0%;
  padding-left: 5vh;
}

.image{
  position: absolute;
  right:0%; 
  padding-right:5vh;
}